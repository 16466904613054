@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap');

.Req-box {
    background-color: #FF916E;
    width: 350px;
    height: 525px;
    margin-top: 50px;
    margin-left: auto;
    margin-right:auto;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.2);
    position: relative;
}

.requestTitle {
    font-family: 'Source Sans Pro', sans-serif;
    text-align: center;
    padding-top: 40px;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 70px;
    color: white;
}

.Light {
    font-weight: 300;
}

.Heavy {
    font-weight: 700;
}

.Esc-button {
    width: 26px;
    height: 26px; 
    border-radius: 100%;
    background-color: transparent;
    border: none;
    right: 5px;
    margin-top: 5px;
    position: absolute;
    padding: 0px;
}

.Esc-button:hover {
    background-color: rgba(0,0,0,.1);
    cursor: pointer;
}

.Esc-button:active {
    background-color: rgba(0,0,0,.2);
}

.Esc-button:focus {
    outline: 0;
    text-decoration: none;
}

.escapeButtonIcon {
    color: white;
    margin-bottom: 5px;
}

.pressForHelp {
    text-align: center;
    margin-top: 80px;
    margin-bottom: 10px;
    font-family: 'Open Sans';
    color: white;
}

.Submit-button {
    display: block;
    margin: auto;
    padding: 4px;
    border-radius: 27px;
    width: 180px;
    height: 180px;
    border: none;
    background-color: white;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.1);
}

.Submit-button:hover {
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.2);
    background-color: rgb(245, 245, 245);
    cursor: pointer;
}

.Submit-button:active {
    background-color: rgb(235, 235, 235);
}

.Submit-button:focus {
    outline: 0;
    text-decoration: none;
}

.exclamationPoint {
    font-size: 90px;
    font-family: 'Open Sans';
    font-weight: 600;
    color: #A7A7A7;
}