@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');

.organizationPageContainer {
    width: 100%;
    height: 100vh;
    box-shadow: 0px 2px 6px black;
    border-radius: 8px;
    overflow: none;
}

.headerContainer {
    background-color: #FF916E;
    display: inline-flex;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid white;
    position: relative;
}

.organizationPageDropdownButton {
    width: fit-content;
    height: 35px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
    margin-right: 35px;
    background-color: transparent;
    border: none;
    color: white;
    text-align: center;
}

.organizationPageDropdownButton:focus {
    outline:none;
}

.organizationPageDropdownContainer {
    z-index: 1;
    top: 70px;
    width: 200px;
    height: fit-content;
    background-color: white;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.1);
    position: absolute;
}

.organizationPageDropdownItem {
    width: 200px;
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 10px;
}

.organizationPageDropdownItem:hover {
    cursor: pointer;
    background-color: rgba(0,0,0,.1);
}

.organizationPageDropdownItemText {
    font-family: 'Open Sans';
    font-size: 20px;
    font-weight: 600;
    margin-left: 10px;
}

.organizationTitle {
    font-family: 'Source Sans Pro';
    font-size: 40px;
    font-weight: bold;
    color: white;
    height: fit-content;
    text-align: center;
    width: 300px;
    margin-top: 10px;
    margin: auto;
}

.restaurantsContainer {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
