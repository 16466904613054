@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap');

.restaurantButton {
    width: 300px;
    height: 227px;
    margin-top: 30px;
    margin-left: 42px;
    margin-right: 42px;
    padding: 0px;
    background-color: white;
    display: inline-block;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.1);
}

.restaurantButton:hover {
    cursor: pointer;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.2);
}

.restaurantButtonTopBar {
    width: 100%;
    height: 38px;
    position: relative;
}

.restaurantButtonDropDown {
    width: 30px;
    height: 30px;
    float: right;
    text-align: center;
    border-radius: 100%;
    margin-right: 10px;
    margin-top: 5px;
}

.restaurantButtonDropDown:hover {
    background-color: rgba(0,0,0,.1);
}

.restaurantButtonDropDownMenu {
    margin-top: 6px;
    width: 150px;
    height: fit-content;
    background-color: white;
    position: absolute;
    left:300px;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.1);
}

.restaurantButtonNameContainer {
    height: 189px;
    width: 100%;
    margin-top: 0px;
    background-color: #FF916E;
}

.restaurantButtonName {
    font-family: 'Source Sans Pro';
    font-size: 50px;
    padding-top: 45px;
    text-align: center;
    color: white;
}

.escButton {
    width: 20px;
    height: 20px;
    margin-top: 45px;
    margin-bottom: 5px;
    margin-left: 5px;
    padding: 0px;
}

.escButton:hover {
    cursor: pointer;
}