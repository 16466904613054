@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap);
.Login-box {
    background-color: #FF916E;
    width: 350px;
    height: 525px;
    margin-top: 50px;
    margin-left: auto;
    margin-right:auto;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.2);
    border-radius: 5px;
    position: relative;
}

.adminButton {
    border-radius: 100%;
    border-style: none;
    width: 36px;
    height: 36px;
    margin-left: auto;
    margin-right: 5px;
    margin-top: 5px;
    background-color: rgba(0,0,0,.1);
    position: absolute;
    right: 0px;
    padding: 0px;
}

.adminButton:hover {
    cursor: pointer;
    background-color: rgba(0,0,0,.2);
}

.adminButton:focus {
    outline: 0;
    text-decoration: none;
}

.adminButton:active {
    background-color: rgba(0,0,0,.25);
}

.adminButtonIcon {
    margin-bottom: 4px;
}

.loginTitle {
    font-family: 'Source Sans Pro', sans-serif;
    text-align: center;
    padding-top: 40px;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 70px;
    color: white;
}

.Light {
    font-weight: 300;
}

.Heavy {
    font-weight: 700;
}

.Enter-text {
    margin-top: 180px;
    text-align: center;
    font-size: 15px;
    color: white;
    font-family: 'Open Sans';
    font-weight: bold;
}

.Input {
    margin-left: auto;
    margin-right: auto;  
    text-align: center;  
}

.Input-box {
    width: 200px;
    height: 60px;
    text-align: center;
    font-size: 35px;
    font-family: sans-serif;
    border: none;
    outline: none;
}

.Go {
    text-align: center;
    padding: 20px;
}

.Go-button {
    width: 120px;
    height: 50px;
    font-size: 25px;
    font-weight: 600;
    font-family: 'Open Sans';
    border-radius: 15px;
    background-color: white;
    border:white;
    box-shadow: 0px 3px 4px 0px rgba(0,0,0,.2);
}

.Go-button:hover {
    background-color: rgb(228, 228, 228);
    cursor: pointer;
}

.Go-button:active {
    background-color: rgb(194, 194, 194);
}

.Go-button:focus {
    outline: 0;
    text-decoration: none;
}


.Req-box {
    background-color: #FF916E;
    width: 350px;
    height: 525px;
    margin-top: 50px;
    margin-left: auto;
    margin-right:auto;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.2);
    position: relative;
}

.requestTitle {
    font-family: 'Source Sans Pro', sans-serif;
    text-align: center;
    padding-top: 40px;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 70px;
    color: white;
}

.Light {
    font-weight: 300;
}

.Heavy {
    font-weight: 700;
}

.Esc-button {
    width: 26px;
    height: 26px; 
    border-radius: 100%;
    background-color: transparent;
    border: none;
    right: 5px;
    margin-top: 5px;
    position: absolute;
    padding: 0px;
}

.Esc-button:hover {
    background-color: rgba(0,0,0,.1);
    cursor: pointer;
}

.Esc-button:active {
    background-color: rgba(0,0,0,.2);
}

.Esc-button:focus {
    outline: 0;
    text-decoration: none;
}

.escapeButtonIcon {
    color: white;
    margin-bottom: 5px;
}

.pressForHelp {
    text-align: center;
    margin-top: 80px;
    margin-bottom: 10px;
    font-family: 'Open Sans';
    color: white;
}

.Submit-button {
    display: block;
    margin: auto;
    padding: 4px;
    border-radius: 27px;
    width: 180px;
    height: 180px;
    border: none;
    background-color: white;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.1);
}

.Submit-button:hover {
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.2);
    background-color: rgb(245, 245, 245);
    cursor: pointer;
}

.Submit-button:active {
    background-color: rgb(235, 235, 235);
}

.Submit-button:focus {
    outline: 0;
    text-decoration: none;
}

.exclamationPoint {
    font-size: 90px;
    font-family: 'Open Sans';
    font-weight: 600;
    color: #A7A7A7;
}
.addTableContainer {
    text-align: center;
    width: 300px;
    height: 500px;
    position: absolute;
    z-index: 1;
    background-color: #FF916E;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.2);
    border-radius: 5px;
    left: 50%;
    margin-left: -150px;
    top: 20%;
    margin-top: -50px;
}

.addTableTitle {
    font-family: 'Open Sans';
    font-size: 45px;
    font-weight: bold;
    color: white;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0 auto;
    margin-top: 40px;
}

.addTableEsc {
    right: 5px;
    top: 5px;
    background-color: transparent;
    border: none;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    padding: 0px;
    position: absolute;
    outline: none;
}

.addTableEsc:hover {
    background-color: rgba(0,0,0,.1);
}

.addTableEsc:active {
    background-color: rgba(0,0,0,.15);
}

.addTableEsc:focus {
    outline: none;
}

.addTableEscIcon {
    padding-bottom: 5px;
    color: white;
    top:auto;
    bottom: auto;
}

.addTableEsc:hover {
    cursor: pointer;
}

.addTableEsc:active {
    border-style: none;
}

.addTableName {
    font-family: 'Open Sans';
    font-size: 15px;
    font-weight: bold;
    color: white;
    margin: 0 auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 200px;
    margin-bottom: auto;
}

.addTableInput {
    width:200px;
    height: 60px;
    margin: 0 auto;
    margin-top: 10px;
    text-align: center;
    font-size: 30px;
    outline: none;
    border: none;
}

.addTableSubmitButton {
    display: block;
    font-family: 'Open Sans';
    font-size: 25px;
    width: 80px;
    height: 40px;
    margin: auto;
    margin-top: 20px;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 3px 4px 0px rgba(0,0,0,.2);
}

.addTableSubmitButton:hover {
    background-color: rgb(228, 228, 228);
}

.addTableSubmitButton:active {
    background-color: rgb(194, 194, 194);
}

.addTableSubmitButton:focus{
    outline: none;
}
.adminPageContainer {
    width: 100%;
    height: 100vh;
    display: inline-flex;
}

.adminPageSideBar {
    width: 14%;
    height: 100vh;
    background-color: #FF916E;
}

.adminPageTitle {
    font-family: 'Source Sans Pro', sans-serif;
    text-align: center;
    font-size: 2.3vw;
    color: white;
    margin-top: 30px;
}

.adminPageAddTableButton {
    margin: 0 auto;
    margin-top: 15px;
    width: 12vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: white;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.1);
    text-align: center;
    position: relative;
}

.adminPageAddTableButton:hover {
    cursor: pointer;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.15);
}

.adminPageAddTableButton:active {
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.2);
}

.adminPageAddTableButtonTextContainer {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: 'Open Sans';
    font-size: 15px;
}

.adminPageAddTableButtonPlus {
    color: #D91313;
    margin-right: 10px;
}

.adminPageMainContainer {
    width: 86vw;
    height: 100vh;
    background-color: #F4F4F4;
    overflow-y: auto;
    overflow-x: auto;
}

.adminPageTopBar {
    width: 100%;
    min-width: 720px;
    height: 40px;
    background-color: white;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.1);
}

.adminPageReturnButton {
    width: 70px;
    height: 30px;
    float: right;
    margin-right: 10px;
    margin-top: 5px;
    border-radius: 20px;
    background-color: transparent;
    border-style: none;
    font-family: 'Open Sans';
}

.adminPageReturnButton:hover {
    background-color: rgba(0,0,0,.1);
}

.adminPageReturnButton:active {
    background-color: rgba(0,0,0,.15);
    outline: none;
}

.adminPageReturnButton:focus {
    outline: none;
}

.adminPageInfoContainer {
    height: 720px;
    width: 100%;
    top: 0px;
    display: inline-flex;
}

.adminPageRequestsContainer {
    width: 30%;
    min-width: 209px;
    height: 650px;
    background-color: white;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.1);
    margin-left: 8%;
    margin-top: 35px;
    border-collapse: collapse;
}

.adminPageRequestsHeader {
    height: 70px;
    width: 100%;
    font-family: 'Source Sans Pro';
    font-weight: 300;
    font-size: 27px;
    border-bottom: 1px solid rgba(0,0,0,.2);
}

.adminPageRequestsHeaderTitle {
    float: left;
    margin-left: 15px;
    margin-top: 15px;
}

.adminPageRequestsHeaderCount {
    float: right;
    margin-right: 28px;
    margin-top: 15px;
}

.adminPageRequestsRequest {
    width: 100%;
    height: 70px;
    font-family: 'Source Sans Pro';
    font-weight: 300;
    font-size: 27px;
    border-bottom: 1px solid rgba(0,0,0,.2);
}

.adminPageRequestsRequestName {
    float: left;
    margin-top: 15px;
    margin-left: 15px;
}

.adminPageRequestsRequestCloseButton {
    float: right;
    margin-right: 15px;
    margin-top: 17px;
    width: 36px;
    height: 36px;
    text-align: center;
    border-radius: 18px;
    background-color: transparent;
}

.adminPageRequestsRequestCloseButton:hover {
    cursor: pointer;
    background-color: rgba(0,0,0,.1);
}

.adminPageRequestsRequestCloseButton:active {
    background-color: rgba(0,0,0,.15);
}

.adminPageRequestsRequestCloseButtonIcon {
    margin-bottom: 10px;
  
}

.adminPageTablesContainer {
    width: 46%;
    min-width: 321px;
    height: 650px;
    background-color: white;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.1);
    margin-left: 8%;
    margin-top: 35px;
    overflow-y: auto;
}

.adminPageTablesHeader {
    height: 70px;
    width: 100%;
    font-family: 'Source Sans Pro';
    font-weight: 300;
    font-size: 27px;
    border-bottom: 1px solid rgba(0,0,0,.2);
}

.adminPageTablesHeaderTitle {
    float: left;
    margin-left: 15px;
    margin-top: 15px;
}

.adminPageTablesHeaderCount {
    float: right;
    margin-right: 28px;
    margin-top: 15px;
}

.adminPageTablesTable {
    width: 100%;
    height: 70px;
    font-family: 'Source Sans Pro';
    font-weight: 300;
    font-size: 27px;
    border-bottom: 1px solid rgba(0,0,0,.2);
    display: inline-flex;
}

.adminPageTablesTableName {
    display: block;
    /* float: left; */
    width: 150px;
    margin-left: 15px;
    margin-top: 15px;
    margin-right: auto;
}

.adminPageTablesTableCode {
    /* float: left; */
    width: 100px;
    margin-top: 15px;
}

.adminPageTablesTableRefreshButton {
    /* float: right; */
    margin-right: 15px;
    margin-top: 17px;
    margin-left: auto;
    width: 36px;
    height: 36px;
    text-align: center;
    border-radius: 18px;
    background-color: transparent;
}

.adminPageTablesTableRefreshButton:hover {
    cursor: pointer;
    background-color: rgba(0,0,0,.1);
}

.adminPageTablesTableRefreshButton:active {
    background-color: rgba(0,0,0,.15);
}

.adminPageTablesTableRefreshButtonIcon {
    margin-bottom: 10px;
}

.adminPageTablesTableCloseButton {
    float: right;
    margin-right: 15px;
    margin-top: 17px;
    width: 36px;
    height: 36px;
    text-align: center;
    border-radius: 18px;
    background-color: transparent;
}

.adminPageTablesTableCloseButton:hover {
    cursor: pointer;
    background-color: rgba(0,0,0,.1);
}

.adminPageTablesTableCloseButton:active {
    background-color: rgba(0,0,0,.15);
}

.adminPageTablesTableCloseButtonIcon {
    margin-bottom: 10px;
}


.organizationPageContainer {
    width: 100%;
    height: 100vh;
    box-shadow: 0px 2px 6px black;
    border-radius: 8px;
    overflow: none;
}

.headerContainer {
    background-color: #FF916E;
    display: inline-flex;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid white;
    position: relative;
}

.organizationPageDropdownButton {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 35px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
    margin-right: 35px;
    background-color: transparent;
    border: none;
    color: white;
    text-align: center;
}

.organizationPageDropdownButton:focus {
    outline:none;
}

.organizationPageDropdownContainer {
    z-index: 1;
    top: 70px;
    width: 200px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: white;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.1);
    position: absolute;
}

.organizationPageDropdownItem {
    width: 200px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 10px;
}

.organizationPageDropdownItem:hover {
    cursor: pointer;
    background-color: rgba(0,0,0,.1);
}

.organizationPageDropdownItemText {
    font-family: 'Open Sans';
    font-size: 20px;
    font-weight: 600;
    margin-left: 10px;
}

.organizationTitle {
    font-family: 'Source Sans Pro';
    font-size: 40px;
    font-weight: bold;
    color: white;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    text-align: center;
    width: 300px;
    margin-top: 10px;
    margin: auto;
}

.restaurantsContainer {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.logoutButton {
    font-family: 'Open Sans';
    color: white;
    width: 80px;
    height: 40px;
    border-radius: 20px;
    border-style: none;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    background-color: transparent;
}

.logoutButton:hover {
    cursor: pointer;
    background-color: rgba(0,0,0,.1)
}

.logoutButton:active {
    background-color: rgba(0,0,0,.15);
    outline: none;
}

.logoutButton:focus {
    outline: none;
}
.restaurantButton {
    width: 300px;
    height: 227px;
    margin-top: 30px;
    margin-left: 42px;
    margin-right: 42px;
    padding: 0px;
    background-color: white;
    display: inline-block;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.1);
}

.restaurantButton:hover {
    cursor: pointer;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.2);
}

.restaurantButtonTopBar {
    width: 100%;
    height: 38px;
    position: relative;
}

.restaurantButtonDropDown {
    width: 30px;
    height: 30px;
    float: right;
    text-align: center;
    border-radius: 100%;
    margin-right: 10px;
    margin-top: 5px;
}

.restaurantButtonDropDown:hover {
    background-color: rgba(0,0,0,.1);
}

.restaurantButtonDropDownMenu {
    margin-top: 6px;
    width: 150px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: white;
    position: absolute;
    left:300px;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.1);
}

.restaurantButtonNameContainer {
    height: 189px;
    width: 100%;
    margin-top: 0px;
    background-color: #FF916E;
}

.restaurantButtonName {
    font-family: 'Source Sans Pro';
    font-size: 50px;
    padding-top: 45px;
    text-align: center;
    color: white;
}

.escButton {
    width: 20px;
    height: 20px;
    margin-top: 45px;
    margin-bottom: 5px;
    margin-left: 5px;
    padding: 0px;
}

.escButton:hover {
    cursor: pointer;
}
.addRestaurantContainer {
    text-align: center;
    width: 300px;
    height: 500px;
    position: absolute;
    z-index: 1;
    background-color: #FF916E;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.2);
    border-radius: 5px;
    left: 50%;
    margin-left: -150px;
    top: 20%;
    margin-top: -50px;
}

.addRestaurantTitle {
    font-family: 'Open Sans';
    font-size: 45px;
    font-weight: bold;
    color: white;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0 auto;
    margin-top: 40px;
}

.addRestaurantEsc {
    right: 5px;
    top: 5px;
    background-color: transparent;
    border: none;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    padding: 0px;
    position: absolute;
    outline: none;
}

.addRestaurantEsc:hover {
    background-color: rgba(0,0,0,.1);
}

.addRestaurantEsc:active {
    background-color: rgba(0,0,0,.2);
}

.addRestaurantEsc:focus {
    outline: none;
}

.addRestaurantEscIcon {
    padding-bottom: 5px;
    color: white;
    top:auto;
    bottom: auto;
}

.addRestaurantEsc:hover {
    cursor: pointer;
}

.addRestaurantEsc:active {
    border-style: none;
}

.addRestaurantName {
    font-family: 'Open Sans';
    font-size: 15px;
    font-weight: bold;
    color: white;
    margin: 0 auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 140px;
    margin-bottom: auto;
}

.addRestaurantInput {
    width:200px;
    height: 60px;
    margin: 0 auto;
    margin-top: 10px;
    text-align: center;
    font-size: 30px;
    outline: none;
    border: none;
}

.addRestaurantSubmitButton {
    display: block;
    font-family: 'Open Sans';
    font-size: 25px;
    width: 80px;
    height: 40px;
    margin: auto;
    margin-top: 20px;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 3px 4px 0px rgba(0,0,0,.2);
}

.addRestaurantSubmitButton:hover {
    background-color: rgb(228, 228, 228);
}

.addRestaurantSubmitButton:active {
    background-color: rgb(194, 194, 194);
}

.addRestaurantSubmitButton:focus{
    outline: none;
}
.FirstTimeLoginContainer {
    text-align: center;
    width: 330px;
    height: 500px;
    position: absolute;
    z-index: 1;
    background-color: #FF916E;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.2);
    border-radius: 5px;
    left: 50%;
    margin-left: -150px;
    top: 20%;
    margin-top: -50px;
}

.FirstTimeLoginHeader {
    display: inline-flex;
    width:300px;
    margin-top: 10px;
}

.FirstTimeLoginTitle {
    font-family: 'Open Sans';
    font-size: 45px;
    font-weight: bold;
    color: white;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0 auto;
    margin-top: 40px;
}

.FirstTimeLoginName {
    margin-top: 18%;
    text-align: center;
    font-size: 15px;
    color: white;
    font-family: 'Open Sans';
    font-weight: bold;
    margin-bottom: 5px;
}

.FirstTimeLoginInput{
    width:200px;
    height: 60px;
    margin: 0 auto;
    margin-top: 5px;
    text-align: center;
    font-size: 30px;
    outline: none;
    border: none;
}

.FirstTimeLoginSubmitButton {
    display: block;
    font-family: 'Open Sans';
    font-size: 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 50px;
    margin: auto;
    margin-top: 20px;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 3px 4px 0px rgba(0,0,0,.2);
    background-color: white;
    padding: 0px 10px
}

.FirstTimeLoginSubmitButton:hover {
    background-color: rgb(228, 228, 228);
}

.FirstTimeLoginSubmitButton:active {
    background-color: rgb(194, 194, 194);
}

.FirstTimeLoginSubmitButton:focus {
    outline: none;
}
