@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap');


.Login-box {
    background-color: #FF916E;
    width: 350px;
    height: 525px;
    margin-top: 50px;
    margin-left: auto;
    margin-right:auto;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.2);
    border-radius: 5px;
    position: relative;
}

.adminButton {
    border-radius: 100%;
    border-style: none;
    width: 36px;
    height: 36px;
    margin-left: auto;
    margin-right: 5px;
    margin-top: 5px;
    background-color: rgba(0,0,0,.1);
    position: absolute;
    right: 0px;
    padding: 0px;
}

.adminButton:hover {
    cursor: pointer;
    background-color: rgba(0,0,0,.2);
}

.adminButton:focus {
    outline: 0;
    text-decoration: none;
}

.adminButton:active {
    background-color: rgba(0,0,0,.25);
}

.adminButtonIcon {
    margin-bottom: 4px;
}

.loginTitle {
    font-family: 'Source Sans Pro', sans-serif;
    text-align: center;
    padding-top: 40px;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 70px;
    color: white;
}

.Light {
    font-weight: 300;
}

.Heavy {
    font-weight: 700;
}

.Enter-text {
    margin-top: 180px;
    text-align: center;
    font-size: 15px;
    color: white;
    font-family: 'Open Sans';
    font-weight: bold;
}

.Input {
    margin-left: auto;
    margin-right: auto;  
    text-align: center;  
}

.Input-box {
    width: 200px;
    height: 60px;
    text-align: center;
    font-size: 35px;
    font-family: sans-serif;
    border: none;
    outline: none;
}

.Go {
    text-align: center;
    padding: 20px;
}

.Go-button {
    width: 120px;
    height: 50px;
    font-size: 25px;
    font-weight: 600;
    font-family: 'Open Sans';
    border-radius: 15px;
    background-color: white;
    border:white;
    box-shadow: 0px 3px 4px 0px rgba(0,0,0,.2);
}

.Go-button:hover {
    background-color: rgb(228, 228, 228);
    cursor: pointer;
}

.Go-button:active {
    background-color: rgb(194, 194, 194);
}

.Go-button:focus {
    outline: 0;
    text-decoration: none;
}

