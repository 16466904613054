@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap');

.adminPageContainer {
    width: 100%;
    height: 100vh;
    display: inline-flex;
}

.adminPageSideBar {
    width: 14%;
    height: 100vh;
    background-color: #FF916E;
}

.adminPageTitle {
    font-family: 'Source Sans Pro', sans-serif;
    text-align: center;
    font-size: 2.3vw;
    color: white;
    margin-top: 30px;
}

.adminPageAddTableButton {
    margin: 0 auto;
    margin-top: 15px;
    width: 12vw;
    height: fit-content;
    background-color: white;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.1);
    text-align: center;
    position: relative;
}

.adminPageAddTableButton:hover {
    cursor: pointer;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.15);
}

.adminPageAddTableButton:active {
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.2);
}

.adminPageAddTableButtonTextContainer {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: 'Open Sans';
    font-size: 15px;
}

.adminPageAddTableButtonPlus {
    color: #D91313;
    margin-right: 10px;
}

.adminPageMainContainer {
    width: 86vw;
    height: 100vh;
    background-color: #F4F4F4;
    overflow-y: auto;
    overflow-x: auto;
}

.adminPageTopBar {
    width: 100%;
    min-width: 720px;
    height: 40px;
    background-color: white;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.1);
}

.adminPageReturnButton {
    width: 70px;
    height: 30px;
    float: right;
    margin-right: 10px;
    margin-top: 5px;
    border-radius: 20px;
    background-color: transparent;
    border-style: none;
    font-family: 'Open Sans';
}

.adminPageReturnButton:hover {
    background-color: rgba(0,0,0,.1);
}

.adminPageReturnButton:active {
    background-color: rgba(0,0,0,.15);
    outline: none;
}

.adminPageReturnButton:focus {
    outline: none;
}

.adminPageInfoContainer {
    height: 720px;
    width: 100%;
    top: 0px;
    display: inline-flex;
}

.adminPageRequestsContainer {
    width: 30%;
    min-width: 209px;
    height: 650px;
    background-color: white;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.1);
    margin-left: 8%;
    margin-top: 35px;
    border-collapse: collapse;
}

.adminPageRequestsHeader {
    height: 70px;
    width: 100%;
    font-family: 'Source Sans Pro';
    font-weight: 300;
    font-size: 27px;
    border-bottom: 1px solid rgba(0,0,0,.2);
}

.adminPageRequestsHeaderTitle {
    float: left;
    margin-left: 15px;
    margin-top: 15px;
}

.adminPageRequestsHeaderCount {
    float: right;
    margin-right: 28px;
    margin-top: 15px;
}

.adminPageRequestsRequest {
    width: 100%;
    height: 70px;
    font-family: 'Source Sans Pro';
    font-weight: 300;
    font-size: 27px;
    border-bottom: 1px solid rgba(0,0,0,.2);
}

.adminPageRequestsRequestName {
    float: left;
    margin-top: 15px;
    margin-left: 15px;
}

.adminPageRequestsRequestCloseButton {
    float: right;
    margin-right: 15px;
    margin-top: 17px;
    width: 36px;
    height: 36px;
    text-align: center;
    border-radius: 18px;
    background-color: transparent;
}

.adminPageRequestsRequestCloseButton:hover {
    cursor: pointer;
    background-color: rgba(0,0,0,.1);
}

.adminPageRequestsRequestCloseButton:active {
    background-color: rgba(0,0,0,.15);
}

.adminPageRequestsRequestCloseButtonIcon {
    margin-bottom: 10px;
  
}

.adminPageTablesContainer {
    width: 46%;
    min-width: 321px;
    height: 650px;
    background-color: white;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.1);
    margin-left: 8%;
    margin-top: 35px;
    overflow-y: auto;
}

.adminPageTablesHeader {
    height: 70px;
    width: 100%;
    font-family: 'Source Sans Pro';
    font-weight: 300;
    font-size: 27px;
    border-bottom: 1px solid rgba(0,0,0,.2);
}

.adminPageTablesHeaderTitle {
    float: left;
    margin-left: 15px;
    margin-top: 15px;
}

.adminPageTablesHeaderCount {
    float: right;
    margin-right: 28px;
    margin-top: 15px;
}

.adminPageTablesTable {
    width: 100%;
    height: 70px;
    font-family: 'Source Sans Pro';
    font-weight: 300;
    font-size: 27px;
    border-bottom: 1px solid rgba(0,0,0,.2);
    display: inline-flex;
}

.adminPageTablesTableName {
    display: block;
    /* float: left; */
    width: 150px;
    margin-left: 15px;
    margin-top: 15px;
    margin-right: auto;
}

.adminPageTablesTableCode {
    /* float: left; */
    width: 100px;
    margin-top: 15px;
}

.adminPageTablesTableRefreshButton {
    /* float: right; */
    margin-right: 15px;
    margin-top: 17px;
    margin-left: auto;
    width: 36px;
    height: 36px;
    text-align: center;
    border-radius: 18px;
    background-color: transparent;
}

.adminPageTablesTableRefreshButton:hover {
    cursor: pointer;
    background-color: rgba(0,0,0,.1);
}

.adminPageTablesTableRefreshButton:active {
    background-color: rgba(0,0,0,.15);
}

.adminPageTablesTableRefreshButtonIcon {
    margin-bottom: 10px;
}

.adminPageTablesTableCloseButton {
    float: right;
    margin-right: 15px;
    margin-top: 17px;
    width: 36px;
    height: 36px;
    text-align: center;
    border-radius: 18px;
    background-color: transparent;
}

.adminPageTablesTableCloseButton:hover {
    cursor: pointer;
    background-color: rgba(0,0,0,.1);
}

.adminPageTablesTableCloseButton:active {
    background-color: rgba(0,0,0,.15);
}

.adminPageTablesTableCloseButtonIcon {
    margin-bottom: 10px;
}

