@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');


.addRestaurantContainer {
    text-align: center;
    width: 300px;
    height: 500px;
    position: absolute;
    z-index: 1;
    background-color: #FF916E;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.2);
    border-radius: 5px;
    left: 50%;
    margin-left: -150px;
    top: 20%;
    margin-top: -50px;
}

.addRestaurantTitle {
    font-family: 'Open Sans';
    font-size: 45px;
    font-weight: bold;
    color: white;
    width: 100%;
    height: fit-content;
    margin: 0 auto;
    margin-top: 40px;
}

.addRestaurantEsc {
    right: 5px;
    top: 5px;
    background-color: transparent;
    border: none;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    padding: 0px;
    position: absolute;
    outline: none;
}

.addRestaurantEsc:hover {
    background-color: rgba(0,0,0,.1);
}

.addRestaurantEsc:active {
    background-color: rgba(0,0,0,.2);
}

.addRestaurantEsc:focus {
    outline: none;
}

.addRestaurantEscIcon {
    padding-bottom: 5px;
    color: white;
    top:auto;
    bottom: auto;
}

.addRestaurantEsc:hover {
    cursor: pointer;
}

.addRestaurantEsc:active {
    border-style: none;
}

.addRestaurantName {
    font-family: 'Open Sans';
    font-size: 15px;
    font-weight: bold;
    color: white;
    margin: 0 auto;
    width: fit-content;
    margin-top: 140px;
    margin-bottom: auto;
}

.addRestaurantInput {
    width:200px;
    height: 60px;
    margin: 0 auto;
    margin-top: 10px;
    text-align: center;
    font-size: 30px;
    outline: none;
    border: none;
}

.addRestaurantSubmitButton {
    display: block;
    font-family: 'Open Sans';
    font-size: 25px;
    width: 80px;
    height: 40px;
    margin: auto;
    margin-top: 20px;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 3px 4px 0px rgba(0,0,0,.2);
}

.addRestaurantSubmitButton:hover {
    background-color: rgb(228, 228, 228);
}

.addRestaurantSubmitButton:active {
    background-color: rgb(194, 194, 194);
}

.addRestaurantSubmitButton:focus{
    outline: none;
}