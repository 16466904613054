@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');

.logoutButton {
    font-family: 'Open Sans';
    color: white;
    width: 80px;
    height: 40px;
    border-radius: 20px;
    border-style: none;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    background-color: transparent;
}

.logoutButton:hover {
    cursor: pointer;
    background-color: rgba(0,0,0,.1)
}

.logoutButton:active {
    background-color: rgba(0,0,0,.15);
    outline: none;
}

.logoutButton:focus {
    outline: none;
}