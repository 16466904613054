.FirstTimeLoginContainer {
    text-align: center;
    width: 330px;
    height: 500px;
    position: absolute;
    z-index: 1;
    background-color: #FF916E;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,.2);
    border-radius: 5px;
    left: 50%;
    margin-left: -150px;
    top: 20%;
    margin-top: -50px;
}

.FirstTimeLoginHeader {
    display: inline-flex;
    width:300px;
    margin-top: 10px;
}

.FirstTimeLoginTitle {
    font-family: 'Open Sans';
    font-size: 45px;
    font-weight: bold;
    color: white;
    width: 100%;
    height: fit-content;
    margin: 0 auto;
    margin-top: 40px;
}

.FirstTimeLoginName {
    margin-top: 18%;
    text-align: center;
    font-size: 15px;
    color: white;
    font-family: 'Open Sans';
    font-weight: bold;
    margin-bottom: 5px;
}

.FirstTimeLoginInput{
    width:200px;
    height: 60px;
    margin: 0 auto;
    margin-top: 5px;
    text-align: center;
    font-size: 30px;
    outline: none;
    border: none;
}

.FirstTimeLoginSubmitButton {
    display: block;
    font-family: 'Open Sans';
    font-size: 20px;
    width: fit-content;
    height: 50px;
    margin: auto;
    margin-top: 20px;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 3px 4px 0px rgba(0,0,0,.2);
    background-color: white;
    padding: 0px 10px
}

.FirstTimeLoginSubmitButton:hover {
    background-color: rgb(228, 228, 228);
}

.FirstTimeLoginSubmitButton:active {
    background-color: rgb(194, 194, 194);
}

.FirstTimeLoginSubmitButton:focus {
    outline: none;
}